<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      class="white--text hero1"
      gradient="to right, rgba(5, 11, 31, .6), rgba(5, 11, 31, .6)"
      src="@/assets/m&h.jpg"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          max-width="700"
          width="100%"
        >
          <v-img src="@/assets/mullshad.png" />
          <br>
          <div class="d-flex flex-wrap">
            <base-btn to="/our-story">
              Our Story
            </base-btn>

            <span class="font-weight-bold ml-2 mr-2 my-2" />

            <base-btn to="contact-us">
              Contact Us
            </base-btn>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<style scoper>
.hero1 {
  width: 100%;
  display: flex;
  background-size: cover;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.butt {
  position: absolute;
  margin-top: 10px;
  right: 10px;
  height: 120px;
  z-index: +2;
}

</style>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '100vh'
        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    methods: {
      logout () {
        this.$store.dispatch('logout')
      },
    },
  }
</script>
